import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './TeamSection.css';

class TeamSection extends React.Component {

  render() {
    const members = [
      {
        name: 'Julia',
        role: 'Finanzen und Geschäftspartner',
        image: {
          url: '/images/team/julia.jpeg'
        },
        quote: 'Aufgrund meiner offenen und kommunikativen Art war schnell klar, dass die Akquise und Pflege unserer Geschäftskunden in mein Aufgabengebiet fällt. '
        + 'Mein BWL-Background hat mich zudem für die Verwaltung unserer Finanzen qualifiziert.'
      },
      {
        name: 'Marie-Jana',
        role: 'Produktentwicklung und PR',
        image: {
          url: '/images/team/mj.jpeg'
        },
        quote: 'Als kreativer Kopf des Teams bin ich für die Produktentwicklung sowie die Erarbeitung marketingtechnischer Konzepte zuständig. Wir arbeiten auf Hochtouren, um BUDDY zu perfektionieren - und ich sorge dafür, dass ihr auch davon mitbekommt.'
      },
      {
        name: 'Felix',
        role: 'IT und Development',
        image: {
          url: '/images/team/felix.jpeg'
        },
        quote: 'Ich bringe das technische Know-How in unser Team und somit obliegt mir die Umsetzung der Software-Konzepte. Da ich im Bereich des Backends bereits für den Datenschutz zuständig bin, kümmere ich mich auch um weitere rechtliche Aspekte.'
      },
    ]

    const memberCards = members.map((m) => this.renderTeamMember(m));

    return (
      <section id="team" className="meet-the-team">
        <div className="container-3 w-container x-heading-container">
          <h2 className="heading-5">Meet our Team.</h2>
          <p className="paragraph-4">
            Damit du weißt, wer hinter den Kulissen wie die drei Musketiere{" "}
            <br />
            gegen deinen inneren Schweinehund ins Rennen geht, <br />
            stellen wir uns kurz vor:
            <br />
            <br />
          </p>
        </div>
        <div className="container-2 w-container">
          <div className="columns w-row">
            {memberCards}
          </div>
        </div>
      </section>
    );
  }

  renderTeamMember(member) {
    return (
      <div className="w-col w-col-4 team-member" key={member.name}>
        <h5 className="heading-7 name">{member.name || <Skeleton />}</h5>
        <img
          src={member.image.url}
          width={800}
          height={200}
          sizes="(max-width: 767px) 96vw, (max-width: 991px) 229.328125px, 299.984375px"
          alt={member.name}
          className="image-6"
        />
        <div className="heading-8 role">
          {member.role !== undefined ? `- ${member.role} -` : <Skeleton />}
        </div>
        <blockquote className="block-quote">
          {member.quote !== undefined ? `"${member.quote}"` : <Skeleton count="4" />}
        </blockquote>
      </div>
    );
  }
}

export default TeamSection;
