import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './App';

import './index.css';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';

import smoothscroll from 'smoothscroll-polyfill';

import './styles/normalize.css';
import './styles/webflow.css';
import './styles/marie-janas-five-star-pro-c49fadaed5c750.webflow.css';
import './styles/custom.css';

// kick off the polyfills here
smoothscroll.polyfill();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

var isReactSnap = navigator.userAgent === 'ReactSnap';
/*
 * Only load tag manager when page is not rendered by react-snap
 * for pre-rendering.
 */
if (!isReactSnap) {
  TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
