import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HeaderMenuItem from './header/HeaderMenuItem';

import './MainHeader.css';

class MainHeader extends React.Component {

  constructor() {
    super();
    this.state = {
      navigationVisible: false
    };

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  toggleNavigation() {
    this.setState(state => ({
      navigationVisible: !state.navigationVisible
    }));
  }

  onMenuItemClick() {
    console.log('click');
    this.setState({
      navigationVisible: false
    })
  }

  render() {
    return (
      <header className="main-header">
        <nav className="new-navbar navbar-default">
          <span
            onClick={this.toggleNavigation}
            className="navbar-toggle w-inline-block">
            <FontAwesomeIcon icon="bars" />
          </span>
          <div className="container-fluid w-clearfix">
            <div className="navbar-header">
              <a
                href="/"
                className="navbar-brand w-inline-block"
              >
                <img
                  src="/logo192.png"
                  alt="BUDDY Logo"
                  height={60}
                  className="image-9"
                />
                <div className="text-block-2 navbar-brand-text">BUDDY</div>
              </a>
            </div>
            <div>
              <nav className={"main-navigation w-clearfix " + (this.state.navigationVisible ? "active" : "")}>
                <ul className="navbar-nav navbar-right header-navbar w-clearfix w-list-unstyled">
                  <li className="menu-item">
                    <div className="html-embed w-embed">
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\na.header-nav-link:hover {\n\tfont-weight:bold;\n}\na.header-nav-link::after {\n\tdisplay:block;\n  content:attr(x-name);\n  font-weight:bold;\n  visibility: hidden;\n  overflow: hidden;\n  height: 0px;\n}\n"
                        }}
                      />
                      {}
                    </div>
                  </li>
                  <HeaderMenuItem
                    onClick={this.onMenuItemClick}
                    to="/install"
                    current={false}
                    highlighted={true}>
                    Jetzt Installieren
                  </HeaderMenuItem>
                {/*
                  <HeaderMenuItem
                    onClick={this.onMenuItemClick}
                    to="/#erlebnisse"
                    current={false}>
                    Erlebnisse
                  </HeaderMenuItem>
                */}
                  <HeaderMenuItem
                    onClick={this.onMenuItemClick}
                    to="/#app"
                    current={false}>
                    Die BUDDY App
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    onClick={this.onMenuItemClick}
                    to="/#team"
                    current={false}>
                    Das Team
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    onClick={this.onMenuItemClick}
                    /*
                     *  The given URL is handled by Shopify which redirects
                     *  to the actual blog 'home' URL.
                     */
                    to="https://shop.buddy-app.de/blog"
                    current={false}>
                    Blog
                  </HeaderMenuItem>
                </ul>
              </nav>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default MainHeader;
