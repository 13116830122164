import React from "react";
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import isExternal from 'is-url-external';

const propTypes = {
  to: PropTypes.string.isRequired,
};

/**
 * Link (in fact HashLink) that also works for external URL's.
 *
 * By default an external URL like 'https://shop.buddy-app.de' would not works
 * with react-routers Link component.
 */
class Link extends React.Component {

  render() {
    return isExternal(this.props.to) ?
      <a href={this.props.to} {...this.props}>
        {this.props.children}
      </a>
      :
      <HashLink {...this.props} />;
  }
}

Link.propTypes = propTypes;

export default Link;
