import React from 'react';

//import IPhoneFrameView from './IPhoneFrameView';

class AppSection extends React.Component {

  render() {
    return (
      <section id="app" className="die-buddy-app">
        <div className="w-container">
          <div className="center-title x-heading-container">
            <h2 className="heading-10">AdE, Langeweile!</h2>
            <p className="subtitle">BUDDY macht deine Freizeit erlebbär.</p>
          </div>
          <div className="w-row feature-desc-center">
            <div className="push-down-column w-col w-col-6">
              <div className="feature-group">
                <h3 className="" style={{fontSize: "30px"}}>Deine Plattform für <span className="highlighted">Inspiration</span></h3>
                <h3 className="">Du möchtest etwas unternehmen, doch dir fehlen die <span className="highlighted">Ideen?</span></h3>
                <p className="paragraph-3">Wische dich einfach durch BUDDYs <b>Vorschläge</b> und finde <br />
                die Aktivität, auf die du wirklich Lust hast!</p>
              </div>
            </div>
            <div className="center w-col w-col-6">
              <img
                src="images/app-screens/inspiration-screens.png"
                width={350}
                x-replace-images-src-set="images/buddyhomescreen-p-500.png 500w, images/buddyhomescreen-p-800.png 800w, images/buddyhomescreen-p-1080.png 1080w, images/buddyhomescreen.png 1296w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 269px, (max-width: 991px) 354px, 429px"
                alt="BUDDY"
                className="image"
              />
            </div>
          </div>

          <div className="w-row feature-desc-center">
            <div className="push-down-column w-col w-col-6 center mobile-hidden">
              <img
                src="images/app-screens/kiez-feed-and-profile-1500.png"
                width={500}
                x-replace-images-src-set="images/buddyhomescreen-p-500.png 500w, images/buddyhomescreen-p-800.png 800w, images/buddyhomescreen-p-1080.png 1080w, images/buddyhomescreen.png 1296w"
                alt="BUDDY"
                className="image"
              />
            </div>
            <div className="w-col w-col-6">
              <div className="feature-group">
                <h3 className="" style={{fontSize: "30px", paddingTop: '15px'}}>Deine Plattform für <span className="highlighted">BUDDYS</span></h3>
                <h3 className="">Triff neue Leute auf dem <span className="highlighted">BUDDY Kiez</span></h3>
                <div style={{textAlign: "left"}}>
                  <p className="paragraph-3">Stelle Fragen und erhalte Insider-Infos von Locals. Teile deine Tipps mit der <b>Community</b> und finde neue BUDDYS für deine Aktivitäten</p>
                  <p className="paragraph-3">Connecte dich <b>online</b>, triff dich <span className="highlighted">offline</span>!</p>
                </div>
              </div>
            </div>
            <div className="push-down-column w-col w-col-4 center mobile-only">
              <img
                src="images/app-screens/kiez-feed-and-profile-750.png"
                width={450}
                x-replace-images-src-set="images/buddyhomescreen-p-500.png 500w, images/buddyhomescreen-p-800.png 800w, images/buddyhomescreen-p-1080.png 1080w, images/buddyhomescreen.png 1296w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 269px, (max-width: 991px) 354px, 429px"
                alt="BUDDY"
                className="image"
              />
            </div>
          </div>

          <div className="w-row feature-desc-center">
            <div className="push-down-column w-col w-col-6">
              <div className="feature-group">
                <h3 className="" style={{fontSize: "30px", paddingTop: '15px'}}>Deine Plattform für <span className="highlighted">Erlebnisse</span></h3>
                <h3 className="">Die Karte zeigt dir wo etwas abgeht</h3>
                <p className="paragraph-3">Endlich hast du dich für eine Unternehmung entschieden.
                  <br />
                  Doch wo geht es nun hin?</p>
                <p className="paragraph-3">BUDDY verbindet dich direkt mit den besten Anbietern <b>in deiner Nähe</b>!</p>
              </div>
            </div>
            <div className="center w-col w-col-6">
              <img
                src="images/app-screens/map-example.png"
                width={350}
                x-replace-images-src-set="images/buddyhomescreen-p-500.png 500w, images/buddyhomescreen-p-800.png 800w, images/buddyhomescreen-p-1080.png 1080w, images/buddyhomescreen.png 1296w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 269px, (max-width: 991px) 354px, 429px"
                alt="BUDDY"
                className="image"
              />
            </div>
          </div>

          {/* Example Item with slider in iPhone frame */}
          {/*
          <div className="w-row feature-desc-center">
            <div className="push-down-column w-col w-col-6">
              <div className="feature-group">
                <h2 className="heading-11">Example</h2>
                <br />
                <br />
                <h3 className="heading-11" style={{fontSize: "30px"}}>Hello World <span className="highlighted">XXX</span></h3>
                <h3 className="heading-11">Foo bar</h3>
                <p className="paragraph-3">Lorem ipsum at dolmir
                  <br />
                  Doch wo geht es nun hin?</p>
                <p className="paragraph-3">BUDDY verbindet dich direkt mit den besten Anbieter <b>in deiner Nähe</b>!</p>
              </div>
            </div>
            <div className="center w-col w-col-6">
              <IPhoneFrameView />
            </div>
          </div>
          */}
        </div>
      </section>
    );
  }
}

export default AppSection;
