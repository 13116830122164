import React from 'react';
import { Helmet } from 'react-helmet';
//import Link from './../Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppSection from './AppSection';
import SupportersSection from './SupportersSection';
import TeamSection from './TeamSection';
import NewsletterSignUp from '../NewsletterSignUp';

import './../Home.css';
import './Home_v2.css';

class HomeView_v2 extends React.Component {

  constructor(props) {
    super(props);
    this.firstSectionRef = React.createRef();
    this.state = {
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextSlide() {
    const { activeIndex } = this.state;
    const index = (activeIndex + 1) % 3;

    this.setState({
      activeIndex: index
    });
  }

  render() {
    const metaDescription = "BUDDY zeigt dir, wie spannend deine Freizeit"
      + " wirklich sein kann und räumt alles aus dem Weg, was zwischen dir und"
      + " deiner Aktivität steht.";

    return (
      <>
        <Helmet>
          <title>BUDDY App | Deine Erlebniszeit-App</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <header id="home" className="home-header">
          <h1>Deine <br/>Erlebniszeit</h1>
          <div className="part left">
            <div className="header-text">
              <h3 className="title">
                Was macht dich eigentlich glücklich?
              </h3>
              <p>
                Manche sagen, es seien die "kleinen Dinge". Wir sagen: Erlebniszeit, und meinen damit mehr als Freizeit, mehr als dein Feierabendbier, Minigolfen mit der Familie, oder Video Streaming auf der Couch. Erlebniszeit ist ein Lebengefühl. Zeit, mehr zu erleben.
              </p>
            </div>
            <div className="app-store-container mobile-hidden">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href='https://apps.apple.com/us/app/buddy-app/id1528071981'>
                  <img alt='Get it on Google Play' src='/images/Download_on_the_App_Store_Badge_US-UK.svg' className="app-store-button" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href='https://play.google.com/store/apps/details?id=de.buddyactivities.buddyapp&utm_source=bdy_web_head'>
                  <img alt='Get it on Google Play' src='/images/Download_on_Google_Play_en.svg' className="app-store-button" />
              </a>
            </div>
          </div>
          <div className={'part right background-image-' + this.state.activeIndex}>
            <div className="app-store-container mobile-only">
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href='https://apps.apple.com/us/app/buddy-app/id1528071981'>
                    <img alt='Get it on Google Play' src='/images/Download_on_the_App_Store_Badge_US-UK.svg' className="app-store-button" />
                </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href='https://play.google.com/store/apps/details?id=de.buddyactivities.buddyapp&utm_source=bdy_web_head'>
                  <img alt='Get it on Google Play' src='/images/Download_on_Google_Play_en.svg' className="app-store-button" />
              </a>
            </div>
          </div>
            
          <div id="home-down" onClick={() => this.onDownButtonClick()}>
            <span id="arrow-down">
              <FontAwesomeIcon icon="angle-down" size="2x" />
            </span>
          </div>
        </header>

        <div ref={this.firstSectionRef}>
          {/*<VideoSection />*/}
          <AppSection />
          <TeamSection />
          <NewsletterSignUp />
          <SupportersSection />
        </div>
      </>
    );
  }

  /*
   * Scrolls to the first section referenced by firstSectionRef and
   * respects the 80px offset necessary because of the top navbar / header.
   */
  onDownButtonClick() {
    //const yOffset = -80;
    const element = this.firstSectionRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset /*+ yOffset*/;

    window.scrollTo({top: y, behavior: 'smooth'});
  }
}

export default HomeView_v2;
