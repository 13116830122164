import React from "react";
import Link from './../Link';

class HeaderMenuItem extends React.Component {

  render() {
    return (
      <li className="menu-item">
        <Link
          onClick={this.props.onClick}
          x-name={this.props.children}
          to={this.props.to}
          className={`navbar-link header-nav-link ${this.props.current ? 'current' : ''} ${this.props.highlighted ? 'highlighted' : ''}`}
        >
          {this.props.children}
        </Link>
      </li>
    );
  }
}

export default HeaderMenuItem;
