import React from "react";

import NewsletterForm from './NewsletterForm';

class NewsletterSignUp extends React.Component {

  render() {
    return (
      <section id="newsletter" className="home-section">
        <div className="container">
          <h3 className="section-heading">Newsletter</h3>
          <p className="section-paragraph">
            Du findest BUDDY interessant und willst keine Neuigkeiten wie den
            Launch der App verpassen? Dann melde Dich jetzt für unseren
            Newsletter an und sei der erste der von neuen Updates erfährt.
          </p>
          <div className="form-block w-form">
          
            <NewsletterForm />

            <div className="success-message w-form-done">
              <h2>Danke für die Eintragung in den Newsletter!</h2>
              <div>
                Du erhälst jetzt noch eine E-Mail, in der Du deine E-Mail
                Adresse bestätigen musst. Nur so können wir sicher sein, dass Du
                den Newsletter auch wirklich erhalten willst und nicht jemand
                anders einfach Deine E-Mail Adresse angegeben hat.
              </div>
            </div>
            <div className="w-form-fail">
              <div>
                Ups! Da ist etwas schiefgelaufen. Versuche es bitte später
                erneut oder melde dich bei{" "}
                <a href="mailto:info@buddy-app.de">info@buddy-app.de</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NewsletterSignUp;
