import React from "react";
import { Helmet } from 'react-helmet';

class InstallApp extends React.Component {
  render() {
    const metaDescription = "Installiere die BUDDY App noch heute aus dem App Store oder Google Play.";

    return (
      <>
        <Helmet>
          <title>BUDDY App | Jetzt Installieren</title>
          <meta
            name="description"
            content={metaDescription} />
        </Helmet>
        <article>
          <header className="container entry-header">
            <div className="text-center">
              <h1>Hol dir die App!</h1>
            </div>
          </header>
          <section className="container">
            <div className="content-wrapper">
              <div className="text-center">
                <p>
                  Lade dir die BUDDY App kostenlos herunter und tritt der coolsten lokalen Freizeit-Community bei!
                </p>
                </div>
                <div className="app-store-container">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href='https://apps.apple.com/us/app/buddy-app/id1528071981'>
                      <img alt='Get it on Google Play' src='/images/Download_on_the_App_Store_Badge_US-UK.svg' className="app-store-button" />
                  </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href='https://play.google.com/store/apps/details?id=de.buddyactivities.buddyapp&utm_source=bdy_web_head'>
                    <img alt='Get it on Google Play' src='/images/Download_on_Google_Play_en.svg' className="app-store-button" />
                </a>
              </div>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export default InstallApp;
