import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faEnvelope , faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import "cookieconsent";
import "cookieconsent/build/cookieconsent.min.css";

// import our isolated Bootstrap
import "./styles/bootstrap-isolated.scss";
// import Bootstrap JavaScript components here

import './App.css';

import ScrollToTop from './ScrollToTop';

import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import Impressum from './components/Impressum';
import PrivacyPolicy from './components/PrivacyPolicy';
import InstallApp from './components/InstallApp';
import NotFoundHeader from './components/NotFoundHeader';


import HomeView_v2 from './components/home/HomeView_v2';

// Init fontawesome
library.add(faBars, faEnvelope, faInstagram, faAngleDown);

class App extends Component {

  constructor(props) {
    super(props);

    var isReactSnap = navigator.userAgent === 'ReactSnap';

    /*
     * Only render cookie consent if not called by react-snap. Otherwise the
     * cookie consent dialog keep stay static on the page and will not hide,
     * even if accept or decline buttons are clicked.
     */
    if (!isReactSnap) {
      /*
       * Initialize cookie and Google Analytics Consent here.
       */
      window.cookieconsent.initialise({
        "palette": {
          "popup": {
            "background": "#3c404d",
            "text": "#ffffff"
          },
          "button": {
            "background": "#e57231",
            "text": "#ffffff"
          }
        },
        "dimissOnScroll": true,
        "position": "bottom",
        "theme": "classic",
        "type": "opt-in",
        "content": {
          "message": "Wir nutzen Cookies und Google Analytics, um diese Website für Dich so interessant wie möglich zu gestalten. Bist Du damit einverstanden? (Du kannst diese Entscheidung jederzeit widerrufen)",
          "deny": "Ablehnen",
          "allow": "Ja, Einverstanden!",
          "link": "mehr",
          "href": "/datenschutz"
        }
      });
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="app-wrapper">
          <MainHeader></MainHeader>

          <main className="content-area">
            <Switch>
              <Route path="/" exact component={HomeView_v2} />
              <Route path="/impressum" exact component={Impressum} />
              <Route path="/datenschutz" exact component={PrivacyPolicy} />
              <Route path="/install" exact component={InstallApp} />
              {/* Legacy routes / redirects */}
              <Route path='/shop'
                component={() => {
                  // WANING: If there is an /shop link in this app react-snap will fail build!
                  window.location.href = 'https://shop.buddy-app.de';
                  return null;
                }}
              />
              <Route path="/_OLD_shop/live-cooking" exact render={() => <Redirect to="/shop/products/live-cooking" />} />
              <Route path='/blog'
                component={() => {
                  // WANING: If there is an /blog link in this app react-snap will fail build!
                  window.location.href = 'https://shop.buddy-app.de/blog';
                  /* The given URL is handled by Shopify which redirects
                   * to the actual blog 'home' URL.
                   */
                  return null;
                }}
              />
              {/* Not found for all other routes */}
              <Route component={NotFoundHeader} />
            </Switch>
          </main>
          <MainFooter></MainFooter>
        </div>
      </Router>
    );
  }
}

export default App;
