import React, { Component } from "react";

class NewsletterForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      consent: false
    }
  }

  render() {
    return (
      <form action="https://buddy-app.us4.list-manage.com/subscribe/post">
        <input type="hidden" name="u" value="becfe3b7b37cc4efc270d6a60"/>
        <input type="hidden" name="id" value="95d3fe7b37" />
        {/*  */}
        <input type="hidden" id="gdpr_37943" name="gdpr[37943]" value="Y" checked readOnly />
        <input type="hidden" id="gdpr_38003" name="gdpr[38003]" value="Y" checked readOnly />
        <input type="hidden" id="gdpr_38007" name="gdpr[38007]" value="Y" checked readOnly />
        <label htmlFor="MERGE0" className="field-label">Deine E-Mail Adresse</label>
        <input
          type="email"
          className="field w-input"
          maxLength={256}
          id="MERGE0"
          name="EMAIL"
          onChange={ (e) => {this.setState({email: e.target.value})} }
          value={this.state.email}
          data-name="Email"
          placeholder="Gib Deine E-Mail Adresse ein"
          required
        />
        <label htmlFor="consent" className="field-label">Einwilligung</label>
        <label className="w-checkbox">
          <input
            type="checkbox"
            id="Einwilligung"
            name="consent"
            checked={this.state.consent}
            onChange={ (e) => {this.setState({consent: e.target.checked})} }
            data-name="Einwilligung"
            required
            className="w-checkbox-input"
          />
          <span htmlFor="Einwilligung" className="w-form-label">
            Ja, ich möchte den Newsletter abonnieren und habe die{" "}
            <a href="datenschutz" target="_blank">
              Datenschutzhinweise{" "}
            </a>
            zur Kenntnis genommen.
          </span>
        </label>
        <p className="section-paragraph small-note">
          Wir verwenden für den Versand von E-Mails - auch aus Gründen der
          Datensicherheit - den Dienstleister Mailchimp. Genauere Hinweise
          zur Datenverarbeitung findest du in unserer{" "}
          <a href="datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
          .
        </p>
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
            {/* Produces a warning, but attaching readOnly may break the 'anti-bot' functionality */}
            <input type="text" name="b_becfe3b7b37cc4efc270d6a60_95d3fe7b37"
              tabIndex="-1" value="" onChange={() => console.log('hello bot!')} />
        </div>
        <div className="w-clearfix">
          <input
            type="submit"
            value="Get Updates"
            data-wait="Please wait..."
            className="form-button submit-button-right w-button"
          />
        </div>
      </form>
    );
  }
}

export default NewsletterForm;
