import React from 'react';

class SupportersSection extends React.Component {

  render() {
    return (
      <section id="supporters" className="home-section">
        <div className="container">
          <h3 className="section-heading">Unsere Unterstützer</h3>
          <div className="supporters-container">
            <a href="https://www.ezw.de"
              target="_blank" rel="noopener noreferrer">
              <img
                src="images/ezw-logo.png"
                alt="EZW - Entrepreneurship Zentrum Witten"
                className="supporter-image" />
            </a>
            <a href="https://www.gruenderstipendium.nrw"
              target="_blank" rel="noopener noreferrer">
              <img
                src="images/partner/gruenderstipendium-nrw.png"
                alt="Gründerstipendium NRW"
                className="supporter-image" />
            </a>
            <a href="https://www.ruhr-tourismus.de"
              target="_blank" rel="noopener noreferrer">
              <img
                src="images/partner/ruhr-tourismus.png"
                alt="Ruhr Tourismus GmbH"
                className="supporter-image" />
            </a>
            <a href="https://www.hochschulwerk.de"
              target="_blank" rel="noopener noreferrer">
              <img
                src="images/partner/hsw.png"
                alt="Hochschulwerk Witten/Herdecke e.V."
                className="supporter-image" />
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default SupportersSection;
