import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

class Impressum extends React.Component {
  render() {
    const metaDescription = "BUDDY Activities UG (haftungsbeschränkt),"
      + " Bahnhofstraße 63, 58452 Witten"
      + " Geschäftsführung: Marie-Jana Güth, Julia Karavasilis, Felix Jordan";

    return (
      <>
        <Helmet>
          <title>BUDDY App - Impressum</title>
          <meta
            name="description"
            content={metaDescription} />
        </Helmet>
        <article>
          <header className="container entry-header">
            <div className="text-center">
              <h1>Impressum</h1>
            </div>
          </header>
          <section className="container">
            <div className="content-wrapper">
              <div className="text-center">
                <div>
                  <h4 className="heading-25">
                    BUDDY Activities UG (haftungsbeschränkt)
                  </h4>
                </div>
              </div>
              <div className="w-row">
                <div className="w-col w-col-6">
                  <div className="media">
                    <h4>Anschrift</h4>
                    <p>
                      Entrepreneurship Zentrum Witten (EZW)
                      <br />
                      Bahnhofstraße 63
                      <br />
                      58452 Witten
                    </p>
                  </div>
                  <div className="media">
                    <h4>Geschäftsführung</h4>
                    <p>
                      Marie-Jana Güth
                      <br />
                      Julia Karavasilis
                      <br />
                      Felix Jordan
                    </p>
                  </div>
                  <div className="media">
                    <h4>Verantworkliche i.S.d § 55 Abs. 2 RStV:</h4>
                    <p>
                      Marie-Jana Güth
                      <br />
                      Entrepreneurship Zentrum Witten (EZW)
                      <br />
                      Bahnhofstraße 63
                      <br />
                      58452 Witten
                      <br />
                    </p>
                  </div>
                </div>
                <div className="w-col w-col-6">
                  <div className="media">
                    <h4>Kontakt</h4>
                    <p>
                      <a href="http://www.buddy-app.de">www.buddy-app.de</a>
                      <br />
                      <a href="mailto:info@buddy-app.de?subject=Hey%20BUDDY">
                        info@buddy-app.de
                      </a>
                    </p>
                    <p>Tel: +49 176 218 713 45</p>
                    <p>
                      AG Bochum HRB 18214
                      <br />
                      USt-ID: DE326638332
                    </p>
                  </div>
                </div>
              </div>
              <p>
                Dieses Impressum ist auch gültig für die BUDDY Activities
                Webauftritte bei Instagram und Twitter
              </p>
              <p>
                <Link to="/datenschutz">Zur Datenschutzerklärung</Link>
              </p>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export default Impressum;
