import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
//import { Link } from 'react-router-dom';

class PrimaryLinkButton extends React.Component {

  render() {
    return (
      <Link to={this.props.to} className="primary-button w-button">
        {this.props.children}
      </Link>
    );
  }
}

export default PrimaryLinkButton;
