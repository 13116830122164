import React from "react";
import { Helmet } from 'react-helmet';

import Cookies from 'universal-cookie';

class PrivacyPolicy extends React.Component {

  disableAnalytics() {
    console.log('setting GA opt-out cookie...');
    const cookies = new Cookies();

    var date = new Date();
    date.setFullYear(date.getFullYear() + 99);
    // Oh no - In 99 years after opting out the cookie will expire *_*
    cookies.set('ga-opt-out', 'true', { path: '/', expires: date });
    console.log('ga-opt-out value: ' + cookies.get('ga-opt-out'));

    // Reload to have a fresh state for tag manager, GA etc.
    window.location.reload(false);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>BUDDY App - Datenschutzerklärung</title>
        </Helmet>

        <article>
          <header className="container entry-header">
            <div className="text-center">
              <h1 className="heading-27">Datenschutzerklärung</h1>
            </div>
          </header>
          <section className="container">
            <div className="content-wrapper">
              <p>
                <strong>
                  Allgemeiner Hinweis und Pflichtinformationen
                  <br />
                  <br />
                </strong>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                Website ist:
                <br />‍<br />
                BUDDY Activities UG (haftungsbeschränkt)
                <br />
                <br />
                Vertreten durch: Marie-Jana Güth, Felix Jordan, Julia Karavasilis
                <br />
                <br />
                Entrepreneurship Zentrum Witten (EZW)
                <br />
                Bahnhofstraße 63
                <br />
                58452 Witten
                <br />
                <br />
                Registergericht: Amtsgericht Bochum <br />
                Registernummer: HRB 18214 <br />
                <br />
                Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
                anderen über die Zwecke und Mittel der Verarbeitung von
                personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
                <br />
                <br />‍
                <strong>
                  Widerruf Ihrer Einwilligung zur Datenverarbeitung
                  <br />
                  <br />‍
                </strong>
                Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
                Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
                Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
                formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
                Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                unberührt.
                <br />
                <br />‍
                <strong>
                  Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
                  <br />
                  <br />‍
                </strong>
                Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
                Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
                Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in
                dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
                stellt eine Liste der Datenschutzbeauftragten sowie deren
                Kontaktdaten bereit:{" "}
                <a
                  href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                  target="_blank" rel="noopener noreferrer"
                >
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                </a>
                .<br />
                <br />‍
                <strong>
                  Recht auf Datenübertragbarkeit
                  <br />
                  <br />‍
                </strong>
                Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erfüllung eines Vertrags automatisiert
                verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
                Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern
                Sie die direkte Übertragung der Daten an einen anderen
                Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
                machbar ist.
                <br />
                <br />‍
                <strong>
                  Recht auf Auskunft, Berichtigung, Sperrung, Löschung
                  <br />
                  <br />‍
                </strong>
                Sie haben jederzeit im Rahmen der geltenden gesetzlichen
                Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
                gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
                Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
                auf Berichtigung, Sperrung oder Löschung dieser Daten.
                Diesbezüglich und auch zu weiteren Fragen zum Thema
                personenbezogene Daten können Sie sich jederzeit über die im
                Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
                <br />
                <br />‍
                <strong>
                  SSL- bzw. TLS-Verschlüsselung
                  <br />
                  <br />‍
                </strong>
                Aus Sicherheitsgründen und zum Schutz der Übertragung
                vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden,
                nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind
                Daten, die Sie über diese Website übermitteln, für Dritte nicht
                mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der
                https:// Adresszeile Ihres Browsers und am Schloss-Symbol in der
                Browserzeile.
                <br />
                <br />‍
                <strong>
                  Datenschutzbeauftragter
                  <br />
                  <br />‍
                </strong>
                Bei Anliegen, oder Fragen im Bezug auf den Datenschutz wenden Sie
                sich gerne an:
                <br />
                E-Mail:{" "}
                <a href="mailto:datenschutz@buddy-app.de?subject=%5BDatenschutz%5D%20-%20Mein%20Anliegen">
                  datenschutz@buddy-app.de
                </a>
                <br />
                <br />‍
                <strong>
                  Server-Log-Dateien
                  <br />
                  <br />‍
                </strong>
                In Server-Log-Dateien erhebt und speichert der Provider der
                Website automatisch Informationen, die Ihr Browser automatisch an
                uns übermittelt. Dies sind:
                <br />‍<br />- Besuchte Seite auf unserer Domain
                <br />- Datum und Uhrzeit der Serveranfrage
                <br />- Browsertyp und Browserversion
                <br />- Verwendetes Betriebssystem
                <br />- Referrer URL
                <br />- Hostname des zugreifenden Rechners
                <br />- IP-Adresse
                <br />‍<br />
                Es findet keine Zusammenführung dieser Daten mit anderen
                Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6
                Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
                eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                <br />
                <br />‍
                <strong>
                  Datenübermittlung bei Vertragsschluss für Warenkauf und
                  Warenversand
                  <br />
                  <br />‍
                </strong>
                Personenbezogene Daten werden nur an Dritte nur übermittelt,
                sofern eine Notwendigkeit im Rahmen der Vertragsabwicklung
                besteht. Dritte können beispielsweise Bezahldienstleister oder
                Logistikunternehmen sein. Eine weitergehende Übermittlung der
                Daten findet nicht statt bzw. nur dann, wenn Sie dieser
                ausdrücklich zugestimmt haben.Grundlage fÃ¼r die Datenverarbeitung
                ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                gestattet.
                <br />
                <br />‍
                <strong>
                  Registrierung auf dieser Website
                  <br />
                  <br />‍
                </strong>
                Zur Nutzung bestimmter Funktionen können Sie sich auf unserer
                Website registrieren. Die übermittelten Daten dienen
                ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes
                oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben
                sind vollständig anzugeben. Andernfalls werden wir die
                Registrierung ablehnen.Im Falle wichtiger Änderungen, etwa aus
                technischen Gründen, informieren wir Sie per E-Mail. Die E-Mail
                wird an die Adresse versendet, die bei der Registrierung angegeben
                wurde.Die Verarbeitung der bei der Registrierung eingegebenen
                Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist
                jederzeit möglich. Für den Widerruf genügt eine formlose
                Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
                Datenverarbeitung bleibt vom Widerruf unberührt.Wir speichern die
                bei der Registrierung erfassten Daten während des Zeitraums, den
                Sie auf unserer Website registriert sind. Ihren Daten werden
                gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche
                Aufbewahrungsfristen bleiben unberührt.
                <br />
                <br />‍
                <strong>
                  Kontaktformular
                  <br />
                  <br />‍
                </strong>
                Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
                Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können
                oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser
                Daten findet ohne Ihre Einwilligung nicht statt.Die Verarbeitung
                der in das Kontaktformular eingegebenen Daten erfolgt
                ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung
                ist jederzeit möglich. Für den Widerruf genügt eine formlose
                Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
                erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
                unberührt.Über das Kontaktformular übermittelte Daten verbleiben
                bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
                zur Speicherung widerrufen oder keine Notwendigkeit der
                Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen
                – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                <br />
                <br />‍
                <strong>
                  Speicherdauer von Beiträgen und Kommentaren
                  <br />
                  <br />‍
                </strong>
                Beiträge und Kommentare sowie damit in Verbindung stehende Daten,
                wie beispielsweise IP-Adressen, werden gespeichert. Der Inhalt
                verbleibt auf unserer Website, bis er vollständig gelöscht wurde
                oder aus rechtlichen Gründen gelöscht werden musste.Die
                Speicherung der Beiträge und Kommentare erfolgt auf Grundlage
                Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf
                Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für
                den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
                Rechtmäßigkeit bereits erfolgter Datenverarbeitungsvorgänge bleibt
                vom Widerruf unberührt.
                <br />
                <br />‍
                <strong>
                  Newsletter-Daten
                  <br />
                  <br />‍
                </strong>
                Zum Versenden unseres Newsletters benötigen wir von Ihnen eine
                E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse
                ist notwendig und der Empfang des Newsletters ist einzuwilligen.
                Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die
                Verwendung der Daten erfolgt ausschließlich für den Versand des
                Newsletters.Die bei der Newsletteranmeldung gemachten Daten werden
                ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten
                Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
                formlose Mitteilung per E-Mail oder Sie melden sich Über den
                „Austragen“-Link im Newsletter ab. Die Rechtmäßigkeit der bereits
                erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
                unberührt.Zur Einrichtung des Abonnements eingegebene Daten werden
                im Falle der Abmeldung gelöscht. Sollten diese Daten für andere
                Zwecke und an anderer Stelle an uns übermittelt worden sein,
                verbleiben diese weiterhin bei uns.
                <br />
                <br />‍
                <strong>
                  Cookies
                  <br />
                  <br />‍
                </strong>
                Unsere Website verwendet Cookies. Das sind kleine Textdateien, die
                Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns
                dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer
                zu machen. Einige Cookies sind Session-Cookies. Solche Cookies
                werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht.
                Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis
                Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei
                Rückkehr auf unserer Website wiederzuerkennen.Mit einem modernen
                Webbrowser können Sie das Setzen von Cookies überwachen,
                einschränken oder unterbinden. Viele Webbrowser lassen sich so
                konfigurieren, dass Cookies mit dem Schließen des Programms von
                selbst gelöscht werden. Die Deaktivierung von Cookies kann eine
                eingeschränkte Funktionalität unserer Website zur Folge haben.Das
                Setzen von Cookies, die zur Ausübung elektronischer
                Kommunikationsvorgänge oder der Bereitstellung bestimmter, von
                Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind,
                erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
                Betreiber dieser Website haben wir ein berechtigtes Interesse an
                der Speicherung von Cookies zur technisch fehlerfreien und
                reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung
                anderer Cookies (z.B. fÃ¼r Analyse-Funktionen) erfolgt, werden
                diese in dieser Datenschutzerklärung separat behandelt.
                <br />
                <br />‍

                <strong>
                  Google Analytics
                  <br />
                  <br />‍
                </strong>
                Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website Google Analytics
                eingesetzt, ein Webanalysedienst der Google Ireland Limited (&bdquo;Google&ldquo;). Die Nutzung
                umfasst die Betriebsart &bdquo;Universal Analytics&ldquo;. Hierdurch ist es möglich, Daten,
                Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und
                so die Aktivitäten eines Nutzers geräteübergreifend zu analysieren.
                <br />

                Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer
                gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
                durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
                an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung
                der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
                Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen Wirtschaftsraum zuvor gekürzt. Wir weisen Sie darauf hin, dass auf dieser Webseite
                Google Analytics um eine IP-Anonymisierung erweitert wurde, um eine anonymisierte Erfassung von
                IP-Adressen (sog. IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von Ihrem
                Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nähere
                Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
                <a
                  href="https://www.google.com/analytics/terms/de.html"
                  target="_blank" rel="noopener noreferrer">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href=
                "https://policies.google.com/?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/?hl=de</a>.
                <br/>
                <br/>


                a) Zwecke der Verarbeitung<br/>
                Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um
                weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                Websitebetreiber zu erbringen.
                <br/>
                <br/>

                b) Rechtsgrundlage<br/>
                Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre Einwilligung gemäß <a href=
                "https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank" rel="noopener noreferrer">Art. 6 Abs. 1 S.1 lit. a DSGVO</a>.
                <br/>

                c) Empfänger / Kategorien von Empfängern<br/>
                Der Empfänger der erhobenen Daten ist Google.
                <br/>
                <br/>

                d) Übermittlung in Drittstaaten<br/>
                Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf Grundlage des
                Angemessenheitsbeschlusses der Europäischen Kommission in die USA übermittelt. Das Zertifikat
                können Sie <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target=
                "_blank" rel="noopener noreferrer">hier</a> abrufen.
                <br/>
                <br/>

                e) Dauer der Datenspeicherung<br/>
                Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs
                verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren
                Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
                <br/>
                <br/>

                f) Betroffenenrechte<br/>
                Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die
                Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
                wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können.
                <br/>
                <br/>

                Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
                der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
                Daten durch Google verhindern, indem Sie das <a href=
                "https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">Browser-Add-on</a> herunterladen
                und installieren. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch
                dieser Website. Um die Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu
                verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen durchführen. Wenn Sie hier
                klicken, wird das Opt-Out-Cookie gesetzt:
                <button onClick={this.disableAnalytics} style={{color: '#56a0d3'}}>
                  <strong>Google Analytics deaktivieren</strong>
                </button>
                <br />
                <br />‍


                <strong>
                  Google AdWords und Google Conversion-Tracking
                  <br />
                  <br />‍
                </strong>
                Unsere Website verwendet Google AdWords. Anbieter ist die Google
                Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United
                States.AdWords ist ein Online-Werbeprogramm. Im Rahmen des
                Online-Werbeprogramms arbeiten wir mit Conversion-Tracking. Nach
                einem Klick auf eine von Google geschaltete Anzeige wird ein
                Cookie für das Conversion-Tracking gesetzt. Cookies sind kleine
                Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert.
                Google AdWords Cookies verlieren nach 30 Tagen ihre Gültigkeit und
                dienen nicht der persönlichen Identifizierung der Nutzer. Am
                Cookie können Google und wir erkennen, dass Sie auf eine Anzeige
                geklickt haben und zu unserer Website weitergeleitet wurden.Jeder
                Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies sind
                nicht über Websites von AdWords-Kunden nachverfolgbar. Mit
                Conversion-Cookies werden Conversion-Statistiken für
                AdWords-Kunden, die Conversion-Tracking einsetzen, erstellt.
                Adwords-Kunden erfahren wie viele Nutzer auf ihre Anzeige geklickt
                haben und auf Seiten mit Conversion-Tracking-Tag weitergeleitet
                wurden. AdWords-Kunden erhalten jedoch keine Informationen, die
                eine persönliche Identifikation der Nutzer ermöglichen. Wenn Sie
                nicht am Tracking teilnehmen möchten, können Sie einer Nutzung
                widersprechen. Hier ist das Conversion-Cookie in den
                Nutzereinstellungen des Browsers zu deaktivieren. So findet auch
                keine Aufnahme in die Conversion-Tracking Statistiken statt.Die
                Speicherung von Conversion-Cookies erfolgt auf Grundlage von Art.
                6 Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein
                berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
                unser Webangebot und unsere Werbung zu optimieren.Einzelheiten zu
                Google AdWords und Google Conversion-Tracking finden Sie in den
                Datenschutzbestimmungen von Google:{" "}
                <a href="https://www.google.de/policies/privacy/"
                  rel="noopener noreferrer" target="_blank">
                  https://www.google.de/policies/privacy/
                </a>
                .Mit einem modernen Webbrowser können Sie das Setzen von Cookies
                überwachen, einschränken oder unterbinden. Die Deaktivierung von
                Cookies kann eine eingeschränkte Funktionalität unserer Website
                zur Folge haben.
                <br />
                <br />‍
                <strong>
                  Google Web Fonts
                  <br />
                  <br />‍
                </strong>
                Unsere Website verwendet Web Fonts von Google. Anbieter ist die
                Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
                USA.Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die
                von uns gewünschte Darstellung unserer Website zu präsentieren,
                unabhängig davon welche Schriften Ihnen lokal zur Verfügung
                stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem
                Server von Google in den USA und der damit verbundenen Weitergabe
                Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und
                welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web
                Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
                Betreiber dieser Website haben wir ein berechtigtes Interesse an
                der optimalen Darstellung und Übertragung unseres Webauftritts.Das
                Unternehmen Google ist für das us-europäische
                Datenschutzübereinkommen „Privacy Shield“ zertifiziert. Dieses
                Datenschutzübereinkommen soll die Einhaltung des in der EU
                geltenden Datenschutzniveaus gewährleisten.Einzelheiten über
                Google Web Fonts finden Sie unter:{" "}
                <a href="https://www.google.com/fonts#AboutPlace:about">
                  https://www.google.com/fonts#AboutPlace:about
                </a>{" "}
                und weitere Informationen in den Datenschutzbestimmungen von
                Google:{" "}
                <a href="https://policies.google.com/privacy/partners?hl=de">
                  https://policies.google.com/privacy/partners?hl=de
                </a>
              </p>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export default PrivacyPolicy;
