import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MainFooter extends React.Component {
  render() {
    return (
      <footer className="main-footer">
        <nav className="new-navbar">
          <div className="container-fluid w-clearfix">
            <div className="footer-nav-wrapper w-clearfix">
              <ul className="navbar-nav w-clearfix w-list-unstyled">
                <li className="menu-item footer-item">
                  <a
                    href="/"
                    className="navbar-link footer-nav-link"
                  >
                    © 2020 BUDDY Activities UG (haftungsbeschränkt)
                  </a>
                </li>
                <li className="menu-item footer-item">
                  <Link
                    to="/impressum"
                    className="navbar-link footer-nav-link"
                  >
                    Impressum
                  </Link>
                </li>
                <li className="menu-item footer-item">
                  <Link to="/datenschutz" className="navbar-link footer-nav-link">
                    Datenschutzerklärung
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav navbar-right center-nav w-clearfix w-list-unstyled">
              <li className="menu-item footer-item inline-menu-item">
                <a
                  href="https://www.instagram.com/die_buddy_app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="navbar-link footer-nav-link"
                >
                  <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
                </a>
              </li>
              <li className="menu-item footer-item inline-menu-item">
                <a
                  href="mailto:hello@buddy-app.de?subject=You've%20got%20mail!"
                  className="navbar-link footer-nav-link"
                >
                  <FontAwesomeIcon icon="envelope" size="2x" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </footer>
    );
  }
}

export default MainFooter;
