import React from "react";
import { Helmet } from 'react-helmet';

import PrimaryLinkButton from "./PrimaryLinkButton";

class NotFoundHeader extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Not Found - BUDDY App</title>
        </Helmet>

        <header className="not-found-header">
          <div className="not-found-container w-container">
            <h1 className="heading">
              <strong>404</strong>
            </h1>
            <p className="main-subtitle">
              Das ist nicht die seite nach der du suchst...
            </p>
            <PrimaryLinkButton to="/">Zurück zur Startseite</PrimaryLinkButton>
          </div>
        </header>
      </>
    );
  }
}

export default NotFoundHeader;
